/* eslint-disable max-len */
import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const Logos = ({ data }) => (
  <div className="logos">
    <div className="logos__inner">
      {data.map((item, index) => (
        <a className="logo" href={item.logoUrl || '#'} key={index}>
          <Img
            className="logo__img"
            fluid={(item?.logoImage?.localFile?.childImageSharp?.fluid ? item.logoImage.localFile.childImageSharp.fluid : item.image.localFile.childImageSharp.fluid)}
            alt={`${item.title ? item.title : 'logo'}`}

          />
          {item.logoText && (
          <span>{item.logoText}</span>
          )}
        </a>
      ))}
    </div>
  </div>
);

Logos.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Logos;

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Gallery = ({ data }) => {
  const [activeItem, setActiveItem] = useState(1);
  const moveCarousel = (event) => {
    if (event === true && activeItem < data.length - 1) {
      setActiveItem(activeItem + 1);
    } else if (event === false && activeItem >= 1) {
      setActiveItem(activeItem - 1);
    }
  };
  return (
    <div className={`gallery gallery__active-item--${activeItem}`}>
      <div className="gallery__controls gallery__controls--back" onClick={() => moveCarousel(false)} role="button" tabIndex={0}>
        <div className="gallery__controls__back">back</div>
      </div>
      <div className="gallery__controls gallery__controls--forward" onClick={() => moveCarousel(true)} role="button" tabIndex={0}>
        <div className="gallery__controls__back">forward</div>
      </div>
      <div className={`gallery__inner gallery__inner--${data.length}`}>
        {data.map((item, index) => (
          <div className="gallery__item" key={index}>
            <Img className="gallery__item-image" alt={item.caption} fluid={item.image.localFile.childImageSharp.fluid} />
            <div className="gallery__image-caption">
              {item.caption}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Gallery.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Gallery;

import React from 'react';
import PropTypes from 'prop-types';

const SocialLinks = ({ data }) => (
  <div className="social-links">
    {data.twitter && (
    <a className="social-link social-link--twitter" href={data.twitter.url} target="_blank" rel="noopener noreferrer">{data.twitter.title}</a>
    )}
    {data.instagram && (
    <a className="social-link social-link--instagram" href={data.instagram.url} target="_blank" rel="noopener noreferrer">{data.instagram.title}</a>
    )}
    {data.facebook && (
    <a className="social-link social-link--facebook" href={data.facebook.url} target="_blank" rel="noopener noreferrer">{data.facebook.title}</a>
    )}
    {data.hashtag && (
    <span className="social-link social-link--hashtag">{data.hashtag}</span>
    )}
  </div>
);

SocialLinks.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SocialLinks;

/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import parse from 'html-react-parser';

const Biography = ({ data }) => {
  const [active, setActive] = useState(false);

  return (
    <div className={`biographies__item ${active ? 'biographies__item--active' : ''}`}>
      <div className="biographies__item-info">
        <div className="biographies__item-name" onClick={() => setActive(!active)} role="button" tabIndex="0">{data.name}</div>
        <div className="biographies__item-title" onClick={() => setActive(!active)} role="button" tabIndex="0">{data.title}</div>
      </div>
      <button className="biographies__item-more" onClick={() => setActive(!active)} type="button">Read More</button>
      <div className="biographies__item-more-content">
        <div className="biographies__item-more-content__inner">
          {(data.photo?.localFile?.childImageSharp) && (
            <Img fluid={data.photo.localFile.childImageSharp.fluid} alt={data.name} className="biographies__item-more-content__thumbnail" />
          )}
          {data.summary && (
          <div className="biographies__item-more-content-bottom">
            {parse(data.summary)}
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

Biography.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Biography;

/* eslint-disable no-undef */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const sortScreenings = (a, b) => {
  if (new Date(a.dateStart).getTime() < new Date(b.dateStart).getTime()) {
    return -1;
  }
  if (new Date(a.dateStart).getTime() > new Date(b.dateStart).getTime()) {
    return 1;
  }
  return 0;
};

const Screenings = ({ data }) => {
  const [expandedState, setExpandedState] = useState('closed');
  const currentScreenings = [];
  const previousScreenings = [];
  let morePreviousScreenings = [];
  const nowTimestamp = new Date().getTime();
  data.forEach((item) => {
    const dateEndTimestamp = (item.dateEnd
      ? new Date(item.dateEnd).getTime()
      : new Date(item.dateStart).getTime());
    if ((dateEndTimestamp + (86400 * 1000)) > nowTimestamp || item.openEndDate === true) {
      currentScreenings.push(item);
    } else {
      previousScreenings.push(item);
    }
  });

  currentScreenings.sort(sortScreenings);
  previousScreenings.sort(sortScreenings);
  morePreviousScreenings = previousScreenings.slice(3);

  return (
    <div className="screenings">
      {currentScreenings.length > 0 && (
        <div className="screenings__upcoming">
          <h5 className="screenings__subtitle">Upcoming</h5>
          <ul>
            {currentScreenings.map((item, index) => (
              <li key={index}>
                <div className="screenings__content">
                  <span className="screenings__title">{item.title}</span>
                  <span className="screenings__date">{`${item.dateStart} ${(item.dateEnd ? ` - ${item.dateEnd}` : '')}`}</span>
                  {item.time && (
                  <span className="screenings__time">
                    ,&nbsp;
                    {item.time}
                  </span>
                  )}
                  {item.directorPresent && (
                  <span className="screenings__director">*</span>
                  )}
                  {item.subtext && (
                  <p className="screenings__subtext">
                    {item.subtext}
                  </p>
                  )}
                </div>
                <a href={item.link.url} target="_blank" rel="noopener noreferrer">{item.link.title}</a>
              </li>
            ))}
          </ul>
          <p className="screenings__note">* Denotes filmmakers in attendance for Q&amp;A</p>
        </div>
      )}
      {previousScreenings.length > 0 && (
        <div className="screenings__previous">
          <h5 className="screenings__subtitle">Past Screenings</h5>
          <ul>
            {previousScreenings.slice(0, 3).map((item, index) => (
              <li key={index}>
                <span className="screenings__title">{item.title}</span>
                <span className="screenings__date">{`${item.dateStart} ${(item.dateEnd ? ` - ${item.dateEnd}` : '')}`}</span>
                <span className="screenings__director">{(item.directorPresent ? '*' : '')}</span>
                <a href={item.link.url} target="_blank" rel="noopener noreferrer">{item.link.title}</a>
              </li>
            ))}

          </ul>
          {morePreviousScreenings && (
            <ul className={`screenings__previous-more screenings__previous-more--${expandedState}`}>
              {morePreviousScreenings.map((item, index) => (
                <li key={index}>
                  <span className="screenings__title">{item.title}</span>
                  <span className="screenings__date">{`${item.dateStart} ${(item.dateEnd ? ` - ${item.dateEnd}` : '')}`}</span>
                  <span className="screenings__director">{(item.directorPresent ? '*' : '')}</span>
                  <a href={item.link.url} target="_blank" rel="noopener noreferrer">{item.link.title}</a>
                </li>
              ))}
            </ul>
          )}
          <button className={`screenings__expand screenings__expand-button screenings__expand-button--${expandedState}`} type="button" onClick={() => setExpandedState('expanded')}>Show More</button>
        </div>
      )}
    </div>
  );
};
Screenings.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Screenings;

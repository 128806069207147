/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import parse from 'html-react-parser';

import Layout from '../components/global/layout';
import SEO from '../components/global/seo';
import GTM from '../components/global/gtm';
import Logos from '../components/logos';
import Quotes from '../components/quotes';
import SignUp from '../components/sign-up';
import '../styles/_days.scss';
import Screenings from '../components/global/screenings';
import Biographies from '../components/global/biographies';
import SocialLinks from '../components/global/social-links';
import Gallery from '../components/global/gallery';

const IndexPage = ({ data }) => {
  useEffect(() => {
    const menu = document.querySelector('.main__menu--initial');
    const menuHeight = menu.offsetHeight + 10;
    const menuScrolled = document.querySelector('.main__menu--scrolled');
    const onScroll = () => {
      if (window.scrollY > menuHeight) {
        if (!menuScrolled.classList.contains('in-view')) {
          menuScrolled.classList.add('in-view');
        }
      } else if (window.scrollY < menuHeight) {
        if (menuScrolled.classList.contains('in-view')) {
          menuScrolled.classList.remove('in-view');
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  });

  const flexData = data.flexData.nodes[0].interactiveMariupol;
  return (
    <Layout>
      <SEO
        title={flexData.generalDays.seoTitle || ''}
        description={flexData.generalDays.seoDescription || ''}
        image={flexData.generalDays.seoImage.localFile.publicURL || ''}
      />
      <GTM />
      <div className="wrapper">
        <div className="top feature-well">
          <div className="main">
            <div className="main__menu main__menu--initial">
              <div className="main__menu--title">20 Days in Mariupol</div>
              {flexData.header.menuItems.map((item, index) => (
                <div className="main__menu--item" key={`${index}-menu`}>
                  <a href={item.menuItem.url}>{item.menuItem.title}</a>
                </div>
              ))}
            </div>
            <div className="main__menu main__menu--scrolled">
              <div className="main__menu--title">20 Days in Mariupol</div>
              {flexData.header.menuItems.map((item, index) => (
                <div className="main__menu--item" key={`${index}-menu`}>
                  <a href={item.menuItem.url}>{item.menuItem.title}</a>
                </div>
              ))}
            </div>
            {flexData.featureWell.laurelsAboveTitle && (
              <div className="main__laurels main__laurels--top">
                <Logos data={flexData.featureWell.laurelsAboveTitle} />
              </div>
            )}
            <Img fluid={flexData.featureWell.mainImage.localFile.childImageSharp.fluid} className="main__image" alt="20 Days in Mariupol main image" />
            {flexData.generalDays.title && (
              <div className="main__text">
                <h1>
                  <span className="main__text--top">20 Days </span>
                  <span className="main__text--bottom">in Mariupol</span>
                </h1>
              </div>
            )}
            {flexData.featureWell.content && (
              <div className="main__content">
                {parse(flexData.featureWell.content)}
              </div>
            )}
            {flexData.featureWell.laurels && (
              <div className="main__laurels main__laurels--bottom">
                <Logos data={flexData.featureWell.laurels} />
              </div>
            )}
            {flexData.featureWell.laurelsSecond && (
              <div className="main__laurels main__laurels--second-row main__laurels--bottom">
                <Logos data={flexData.featureWell.laurelsSecond} />
              </div>
            )}
            <a href="#trailer" className="main__trailer-button">
              Watch the Trailer
            </a>
            <div className="main__logos">
              <Logos data={flexData.featureWell.headerLogos} />
            </div>
          </div>
        </div>

        <div className="middle">
          {flexData.flexibleContent.map((item, index) => (
            <>
              {item.__typename.includes('GeneralContent') && (
              <div className={`content ${item.style === 'padded' ? 'content-padded' : ''} ${item.darkBackground === true ? 'content--dark-background' : ''}`} key={index} id={(item.id ? item.id : '')}>
                {item.heading && (
                  <h4>{item.heading}</h4>
                )}
                {parse(item.contentBlock)}
                {item.includeSocial && (
                  <SocialLinks data={flexData.generalDays.socialLinks} />
                )}
              </div>
              )}
              {item.__typename.includes('Screenings') && (
                <div className={`content ${item.style === 'padded' ? 'content-padded' : ''} ${item.darkBackground === true ? 'content--dark-background' : ''}`} key={index} id={(item.id ? item.id : 'screenings')}>
                  {item.heading && (
                  <h4>{item.heading}</h4>
                  )}
                  <Screenings data={item.screening} />
                </div>
              )}
              {item.__typename.includes('Quotes') && (
                <div className={`content ${item.style === 'padded' ? 'content-padded' : ''} ${item.darkBackground === true ? 'content--dark-background' : ''}`} key={index} id={(item.id ? item.id : 'reviews')}>
                  {item.heading && (
                  <h4>{item.heading}</h4>
                  )}
                  <div className="main__quotes">
                    <Quotes data={item.quote} />
                  </div>
                </div>
              )}
              {item.__typename.includes('Accolades') && (
                <div className={`content ${item.style === 'padded' ? 'content-padded' : ''} ${item.darkBackground === true ? 'content--dark-background' : ''}`} key={index} id={(item.id ? item.id : 'accolades')}>
                  {item.heading && (
                  <h4>{item.heading}</h4>
                  )}
                  <Logos data={item.accolades} />
                </div>
              )}
              {item.__typename.includes('Biographies') && (
                <div className={`content ${item.style === 'padded' ? 'content-padded' : ''} ${item.darkBackground === true ? 'content--dark-background' : ''}`} key={index} id={(item.id ? item.id : 'biographies')}>
                  {item.heading && (
                  <h4>{item.heading}</h4>
                  )}
                  <Biographies data={item.biography} />
                </div>
              )}
              {item.__typename.includes('Gallery') && (
                <div className={`content ${item.style === 'padded' ? 'content-padded' : ''} ${item.darkBackground === true ? 'content--dark-background' : ''}`} key={index} id={(item.id ? item.id : 'gallery')}>
                  {item.heading && (
                  <h4>{item.heading}</h4>
                  )}
                  <Gallery data={item.images} />
                </div>
              )}
            </>
          ))}
        </div>
        <div className="bottom">
          <SignUp data={flexData.generalDays} />
        </div>
      </div>
      {flexData.footer.credits && (
        <div className="credits">
          {parse(flexData.footer.credits)}
        </div>
      )}
      <div className="funders">
        <Logos data={flexData.footer.logos} />
      </div>
    </Layout>
  );
};

export const query = graphql`
{
  flexData: allWpInteractive(filter: {slug: {eq: "20-days-in-mariupol"}}) {
    nodes {
      slug
      interactiveMariupol {
        featureWell {
          content
          mainImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          laurels {
            logoImage {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            logoUrl
          }
          laurelsAboveTitle {
            logoImage {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            logoUrl
          }
          laurelsSecond {
            logoImage {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            logoUrl
          }
          headerLogos {
            logoText
            logoUrl
            logoImage {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        header {
          menuItems {
            menuItem {
              target
              title
              url
            }
          }
        }
        footer {
          credits
          logos {
            logoText
            logoUrl
            logoImage {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        generalDays {
          seoDescription
          seoTitle
          title
          newsletterHeading
          newsletterSubhead
          seoImage {
            localFile {
              publicURL
            }
          }
          socialLinks {
            facebook {
              title
              url
            }
            instagram {
              title
              url
            }
            twitter {
              title
              url
            }
            hashtag
          }
        }
        flexibleContent {
          ... on WpInteractive_Interactivemariupol_FlexibleContent_GeneralContent {
            contentBlock
            heading
            id
            style
            darkBackground
            includeSocial
          }
          ... on WpInteractive_Interactivemariupol_FlexibleContent_Screenings {
            fieldGroupName
            heading
            id
            style
            darkBackground
            screening {
              dateEnd
              dateStart
              time
              subtext
              directorPresent
              openEndDate
              link {
                title
                url
              }
              title
            }
          }
          ... on WpInteractive_Interactivemariupol_FlexibleContent_Quotes {
            heading
            id
            style
            darkBackground
            quote {
              stars
              quoteAuthor
              quoteSuphead
              quoteLogo {
                localFile {
                  childrenImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              quotePublication {
                title
                url
              }
              quoteText
            }
          }
          ... on WpInteractive_Interactivemariupol_FlexibleContent_Biographies {
            heading
            id
            style
            darkBackground
            biography {
              name
              summary
              title
              photo {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on WpInteractive_Interactivemariupol_FlexibleContent_Accolades {
            heading
            id
            style
            darkBackground
            accolades {
              title
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on WpInteractive_Interactivemariupol_FlexibleContent_Gallery {
            id
            heading
            style
            darkBackground
            images {
              caption
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;

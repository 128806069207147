import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const SignUp = ({ data }) => (
  <div className="signup">
    <h4>{parse(data.newsletterHeading)}</h4>
    <h5>{parse(data.newsletterSubhead)}</h5>
    <form action="//links.iterable.com/lists/publicAddSubscriberForm?publicIdString=ea369bc6-0843-4718-b30a-4ad9d5a97918" method="post" data-id="global-signup">
      <input type="hidden" name="program_src" value="frontline_20days_splash" />
      <div className="inputs">
        <input type="email" placeholder="Your email address" name="email" id="newsletter-email" />
        <input className="newsletter__input newsletter__input--zip" type="text" required placeholder="Your postal code" name="zipOrPostal" />
      </div>
      <button className="button-alt button" type="submit" value="Submit"><span className="button-text">Sign Up</span></button>
    </form>
  </div>
);

SignUp.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SignUp;

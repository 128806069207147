import React, { useState } from 'react';
import Img from 'gatsby-image';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

const Quotes = ({ data }) => {
  const initialReviewCount = 4;
  const [showMore, setShowMore] = useState(false);
  const firstReviews = data.slice(0, initialReviewCount);
  const moreReviews = data.slice(initialReviewCount);
  return (
    <div className="quotes">
      <div className="quotes__inner">
        {firstReviews.map((item, index) => (
          <a href={item.quotePublication.url} target="_blank" rel="noopener noreferrer" key={index} className="quote">
            {item.stars && (
            <p className="quote__stars">{item.stars}</p>
            )}
            {item.quoteSuphead && (
              <span className="quote__text">
                {item.quoteSuphead}
              </span>
            )}
            {item.quoteText && (
            <span className="quote__text">
              &ldquo;
              {parse(item.quoteText)}
              &rdquo;
            </span>
            )}

            {item.quoteLogo?.localFile?.childrenImageSharp && (
              <Img className="quote__img" fluid={item.quoteLogo.localFile.childrenImageSharp[0].fluid} alt="quote" />
            )}
            {item.quoteAuthor && (
            <span className="quote__attribution">
              {item.quoteAuthor}
            </span>
            )}
          </a>
        ))}
        {moreReviews.length > 0 && (
          <>
            <button className={`quotes__expand ${showMore ? 'quotes__expand--hidden' : ''}`} type="button" onClick={() => setShowMore(true)}>Show More</button>
            <div className={`quotes__inner quotes__inner--more ${(showMore ? 'quotes__inner--more-expanded' : '')}`}>
              {moreReviews.map((item, index) => (
                <a href={item.quotePublication.url} target="_blank" rel="noopener noreferrer" key={index} className="quote">
                  {item.stars && (
                  <p className="quote__stars">{item.stars}</p>
                  )}
                  {item.quoteSuphead && (
                  <span className="quote__text">
                    {item.quoteSuphead}
                  </span>
                  )}
                  {item.quoteText && (
                  <span className="quote__text">
                    &ldquo;
                    {parse(item.quoteText)}
                    &rdquo;
                  </span>
                  )}
                  {item.quoteLogo?.localFile?.childrenImageSharp && (
                  <Img className="quote__img" fluid={item.quoteLogo.localFile.childrenImageSharp[0].fluid} alt="quote" />
                )}
                  {item.quoteAuthor && (
                  <span className="quote__attribution">
                    {item.quoteAuthor}
                  </span>
                  )}
                </a>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Quotes.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Quotes;

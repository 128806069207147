import React from 'react';
import PropTypes from 'prop-types';
import Biography from './biography';

const Biographies = ({ data }) => (
  <div className="biographies">
    {data.map((item, index) => (
      <Biography data={item} key={index} />
    ))}
  </div>
);

Biographies.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Biographies;

import React from 'react';
import Helmet from 'react-helmet';

const GTM = () => (
  <Helmet>
    <link rel="preconnect" href="https://www.googletagmanager.com" />
    <link rel="preconnect" href="https://www.google-analytics.com/" />
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-0ZH3CC17FD" />
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-0ZH3CC17FD');
      `}
    </script>

    <script>
      {`
      window.GTMDataLayer = window.GTMdataLayer || [];
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','GTMDataLayer','GTM-TWTDGH');
      `}
    </script>
    <script type="application/ld+json">
      {`{
        "@context": "http://schema.org",
        "@type": "Movie",
        "name": "20 Days In Mariupol",
        "description": "FRONTLINE and AP’s Sundance Audience Award-winning documentary 20 Days in Mariupol follows an AP team of Ukrainian journalists trapped in the besieged city of Mariupol, Ukraine, drawing on filmmaker Mstyslav Chernov’s daily news dispatches and personal footage of his own country at war.",
        "image": "https://20daysinmariupol.com/static/22d08ef095614ab629d4b7bc4cab4d59/mariupol_signature_16-9.jpg",
        "url": "https://20daysinmariupol.com/",
        "genre": "Documentaries",
        "dateCreated": "2023-07-14",
        "director": [
          {
            "@type": "Person",
            "name": "Mstyslav Chernov"
          }
        ],
        "trailer": {
            "@type": "VideoObject",
            "name": "20 Days in Mariupol (Trailer)",
            "description": "FRONTLINE and AP’s Sundance Audience Award-winning documentary 20 Days in Mariupol follows an AP team of Ukrainian journalists trapped in the besieged city of Mariupol, Ukraine, drawing on filmmaker Mstyslav Chernov’s daily news dispatches and personal footage of his own country at war.",
            "thumbnailUrl": "https://20daysinmariupol.com/static/22d08ef095614ab629d4b7bc4cab4d59/mariupol_signature_16-9.jpg",
            "duration": "PT1M48S",
            "contentUrl": "https://youtu.be/9H_Fg_5x4ME",
            "embedUrl": "https://www.youtube.com/embed/9H_Fg_5x4ME",
            "uploadDate": "2023-06-12T00:00:00-04:00"
          }
      }`}
    </script>
  </Helmet>
);

export default GTM;
